.main {
  padding: 85px 13px 250px;
  margin: 0 auto;
  width: 380px;
  .title-with-photo {
    display: flex;
    justify-content: center;
    padding: 0px 100px 30px;
    background-color: grey;
    border-radius: 60px 0;
    .title {
      .welcome-panel {
        padding: 22px 10px 14px;
        .heading-footer {
          margin-top: 20px;
        }
      }

      .welcome-img {
        height: 300px;
        width: 300px;
        img {
          border-radius: 0px 60px;
        }
      }
    }
  }

  .description {
    padding: 80px 10px 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4;
    text-shadow: 7px 7px 10px rgba(66, 68, 90, 1);
    p {
      font-size: 19px;
      padding: 25px;
      background-color: grey;
      border-radius: 60px 0;
    }
  }
}



@media screen and (min-width: 560px) {
  .main {
    width: 500px;
    .description {
      p {
        -webkit-box-shadow: 54px 55px 0px 0px rgba(28, 28, 28, 1);
        -moz-box-shadow: 54px 55px 0px 0px rgba(28, 28, 28, 1);
        box-shadow: 54px 55px 0px 0px rgba(28, 28, 28, 1);
      }
    }
    .title-with-photo {
      background-color: grey;
      border-radius: 60px 0;
      -webkit-box-shadow: 54px 55px 0px 0px rgba(28, 28, 28, 1);
      -moz-box-shadow: 54px 55px 0px 0px rgba(28, 28, 28, 1);
      box-shadow: 54px 55px 0px 0px rgba(28, 28, 28, 1);
      text-shadow: 7px 7px 10px rgba(66, 68, 90, 1);
  }
}
}

@media screen and (min-width: 870px) {
  .main {
    display: flex;
    width: 100vw;
    margin: -3px auto;
    .description {
      padding: 25px 100px;
      display: flex;
      align-items: center;
      p {
        font-size: 20px;
      }
    }
    .title-with-photo {
      font-size: 20px;
      margin: 50px 0;
      padding: 0px 70px 30px;
      .title {
        .welcome-img {
          height: 350px;
          width: 350px;
          img {
            border-radius: 0px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1220px) {
  .main {
    width: 1280px;
    .description {
      p {
        padding: 25px 100px;
      }
    }
  }
}