.header {
  height: 70px;
  position: fixed;
  width: 100%;
  background-color: rgba(28, 28, 28, 1);
  z-index: 1;
  -webkit-box-shadow: 0px 5px 10px -8px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 5px 10px -8px rgba(0, 0, 0, 1);
  box-shadow: 0px 5px 10px -8px rgba(0, 0, 0, 1);

  img {
    position: absolute;
    width: 225px;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  .hamburger-bars {
    position: absolute;
    display: flex;
    align-items: center;
    padding: 10px 10px 10px;
    z-index: 1;
    height: 100%;
  }
  .menu {
    position: absolute;
    width: 230px;
    height: 2000px;
    top: 0;
    background-color: rgba(28, 28, 28, 1);
    ul {
      margin-top: 100px;
      li {
        margin: 35px;
        font-size: 18px;
      }
    }
  }
  .menu-big {
    display: none;
  }
}

.menu-enter {
  left: -225px;
}

.menu-enter-active {
  left: 0;
  transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  body {
    filter: blur(5px);
  }
}

.menu-exit {
  left: 0;
}

.menu-exit-active {
  left: -225px;
  transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.menu-exit-done {
  left: -225px;
}

@media screen and (min-width: 700px) {
  .header {
    .menu {
      width: 300px;
      ul {
        li {
          margin: 40px;
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    img {
      left: 0;
      transform: translate(30%, -40%);
    }

    .hamburger-bars {
      visibility: hidden;
    }
    .menu-big {
      display: flex;
      ul {
        display: flex;
        margin: 0 25px;
        li {
          margin: 0 25px;
          font-size: 18px;
          a {
            position: relative;
          }
          a::before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 2px;
            bottom: -5px;
            left: 0;
            background-color: white;
            transform: scaleX(0);
            transition: transform 0.3s ease;
          }
          a:hover::before {
            transform: scaleX(1);
          }
        }
      }
    }
  }
}
