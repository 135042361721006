.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(28, 28, 28, 1);
  height: 190px;
  -webkit-box-shadow: 0px -5px 10px -8px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px -5px 10px -8px rgba(0, 0, 0, 1);
  box-shadow: 0px -5px 10px -8px rgba(0, 0, 0, 1);
  .icons-list {
    display: flex;
    justify-content: center;
    font-size: 35px;
    padding: 20px;
    li {
      padding: 0 15px;
    }
  }

  .menu ul {
    font-size: 15px;
    padding: 10px 0 15px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
    li {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
    }
  }

  .copyrights {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    background-color: rgba(28, 28, 28, 1);
    font-size: 11px;
    line-height: 13px;
    padding: 20px 0;
  }
}

@media screen and (min-width: 1000px) {
  .footer {
    .menu ul {
      display: flex;
      justify-content: center;
      font-size: 18px;
      li {
        margin: 0 20px;
      }
      a {
        position: relative;
      }
      a::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        bottom: -5px;
        left: 0;
        background-color: white;
        transform: scaleX(0);
        transition: transform 0.3s ease;
      }
      a:hover::before {
        transform: scaleX(1);
      }
    }
  }
}

@media screen and (min-width: 645px) {
  .footer {
    .menu ul {
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    }
  }
}
