.movies {
  margin: 0 auto;
  display: flex;
  .videos {
    padding: 15px;
    margin: 10px 0 100px;
    width: 100%;
    .video-player {
      width: 100%;
      height: 300px;
    }
  }
  .react-player {
    background-color: black;
    border: 2px solid rgb(85, 85, 85);
    border-radius: 20px;
    padding: 25px 25px 15px;
    margin: 30px 0;
  }
}

@media screen and (min-width: 700px) {
  .movies {
    .videos {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      gap: 50px;
      .video-player {
        width: 100%;
        height: 300px;
      }
    }
  }
}
