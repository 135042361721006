.main-page {
  hr {
    margin: 0 25px
  }
  margin: -3px auto;
  padding: 85px 0px 175px;
  .title-with-description {
    .title {
      display: flex;
      justify-content: center;
      padding: 10px;
    }

    .description {
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 20px 8px;
      font-size: 18px;
      line-height: 1.5;
    }
  }
}

@media screen and (min-width: 1000px) {
  .main-page {
    hr {
      margin: 0px
    }
    padding: 125px 250px;
    .title-with-description {
      .title {
        padding-bottom: 30px;
        font-size: 20px;
      }

      .description {
        padding: 20px 0px 30px;
        font-size: 20px;
        line-height: 1.5;
      }
    }
  }
}
