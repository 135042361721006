.main-page {
  .description {
    display: flex;
    flex-direction: column;
  }
}

.contact-form {
  display: flex;
  margin: 40px auto;
  width: 300px;
  padding: 25px 25px 15px;
  background-color: rgb(128, 128, 128);
  border: 2px solid black;
  border-radius: 20px;
  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;

    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(28, 28, 28, 1);
      }
    }

    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(28, 28, 28, 1);
      }
    }

    label {
      margin-top: 1rem;
    }

    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: rgb(68, 68, 68);
      color: white;
      border: none;
    }
  }
}
@media screen and (min-width: 550px) {
  .contact-form {
    display: flex;
    margin: 30px auto;
    width: 500px;
  }
}
@media screen and (min-width: 1200px) {
  .contact-form {
    display: flex;
    margin: 30px auto;
    width: 700px;
  }
}
