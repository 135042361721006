.main-page {
  .graphics-content {
    margin-bottom: -135px;
    .graphics {
      .graphic {
        margin: 30px 20px;
        padding: 25px 25px 15px;
        background-color: grey;
        border: 2px solid black;
        border-radius: 20px;
       
        img{
          border-radius: 15px;
        }
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .main-page {
    
    .graphics-content {
      margin-bottom: 80px;
      .graphics {
        .graphic {
          margin: 40px 85px;
        }
      }
    }
  }
}
@media screen and (min-width: 1000px) {
  .main-page {
   
    .graphics-content {
      display: flex;
      justify-content: center;
      .graphics {
        .graphic {
          width: 700px;
        }
      }
    }
  }
}
