.main-page {
  .lector-container {
    .audio-files {
      display: flex;
      text-align: center;
      justify-content: center;
      flex-direction: column;
      margin: 30px 0;
      .audio {
        display: inline-flex;
        border: 2px solid black;
        background-color: grey;
        border-radius: 30px;
        margin: 10px 0px 0;
        padding: 15px 10px;
        margin: 10px;
      }
     
      audio {
        margin: 5px;
      }
    }
    .videos {
      padding: 20px;
      margin-bottom: 100px;
      width: 100%;
      .video-player {
        width: 100%;
        padding: 0px 5px;
        height: 350px;
        margin: 15px 0px;
      }
    }
    .react-player {
      background-color: black;
      border: 2px solid rgb(85, 85, 85);
      border-radius: 20px;
      padding: 25px 25px 15px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .main-page {
    .lector-container {
      .audio-files {
        audio {
          width: 300px;
        }
        .audio {
          padding: 25px 15px;
        }
      }
      .first-row {
        display: flex;
        margin: 0 auto;
      }
      .second-row {
        margin: 0 auto;
      }
      .videos {
        .second-row {
          width: 680px;
        }
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .main-page {
    .lector-container {
      .audio-files {
        audio {
          width: 400px;
        }
        .audio {
          padding: 30px;
        }
      }
    }
  }
}
