.sliders {
  width: 380px;
  margin: 0 auto;
  .image-slider {
    margin: 30px 25px;
    padding: 25px 25px 15px;
    background-color: grey;
    border: 2px solid black;
    border-radius: 20px;
    .slick-dots {
      position: relative;
      bottom: 0;
    }
    .slider-container {
      width: 100%;
      height: 100%;

    }
    .images-big {
      img {
        width: 330px;
        height: 440px;
        object-fit: cover;
      }
    }
    .images-small {
      img {
        width: 335px;
        height: 200px;
        object-fit: none;
      }
    }
    .images-medium {
      img {
        width: 330px;
        height: 200px;
        object-fit: cover;
      }
    }
  }
}
@media screen and (min-width: 650px) {
  .sliders {
    width: 600px;
    margin: 50px auto 100px;
    .image-slider {
      padding: 50px 70px 9px;
      border-radius: 60px;

      .images-big {
        img {
          width: 550px;
          height: 660px;
        }
      }

      .images-medium {
        img {
          width: 550px;
          height: 400px;
        }
      }
    }
  }
}
