:root {
  --font-color-white: rgba(255, 255, 255);
  --background-color: rgb(8, 15, 19);
}

* {
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
  scrollbar-width: thin;
  scrollbar-color: #1c1c1c;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(20, 20, 20);
  border-radius: 30px;
  border: 3px solid rgb(20, 20, 20);
}

::-webkit-scrollbar-track {
  background-image: linear-gradient(to bottom, #1c1c1c, #202020, #242424, #282828, #2c2c2c);
}

body {
  position: relative;
  min-height: 100vh;
  background-image: linear-gradient(to right bottom, #181818, #202020, #282828, #303030, #383838);
  color: var(--font-color-white);
  font-family: "Roboto", sans-serif;
  width: 100%;
  overflow: overlay;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  max-height: 100%;
}

button {
  cursor: pointer;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--font-color-white);
}
